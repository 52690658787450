import { useMutation, useQuery } from '@apollo/client';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { FC, MouseEvent, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Divider from '~/components/Divider';
import { PATH } from '~/constants/enum';
import { ADD_PRODUCT_TO_WISH_LIST, QUERY_GET_ACTUAL_PRODUCT, REMOVE_PRODUCT_FROM_WISH_LIST } from '~/data/home';
import { Product } from '~/services/product';
import { trackingWishList } from '~/services/tracking';
import { UserAtom } from '~/services/user';
import { WishListAtom } from '~/services/wishlist';
import { getConfig, getUrlCustom } from '~/utils/theme';
import { transformProductItem } from '~/utils/transform';
import ProductImage, { Props as ProductImageProps } from './ProductImage';
import styles from './ProductItem.module.scss';
import ProductName, { Props as ProductNameProps } from './ProductName';
import ProductPrice from './ProductPrice';

export type ProductItemSize = 'large' | 'medium' | 'small' | 'xsmall' | 'smaller';

interface ProductItemProps {
    size?: ProductItemSize;
    data: Partial<Product>;
    search?: string;
    onProductClick?: (product: Partial<Product>) => void;
    params?: any;
    loading?: boolean;
    className?: string;
    rootClassName?: string;
    onRemove?: (data: Partial<Product>) => void;
    actions?: (data: Partial<Product>) => JSX.Element;
    type?: 'vertical' | 'horizontal';
    imageProps?: Partial<ProductImageProps>;
    nameProps?: Partial<ProductNameProps>;
}

const ProductItem: FC<ProductItemProps> = ({
    size = 'medium',
    data: optimizeData,
    search,
    onProductClick,
    onRemove,
    params = {},
    loading: productItemLoading,
    className = '',
    rootClassName,
    actions,
    type = 'vertical',
    imageProps,
    nameProps
}) => {
    const themeConfig = getConfig('ProductItem');
    const currentUser = useRecoilValue(UserAtom.currentUser);
    const setWishList = useSetRecoilState(WishListAtom.wishlist);
    const { wishlistItems, id } = useRecoilValue(WishListAtom.WishlistSelector);
    const productWishlist = wishlistItems?.find((wishlistItem) => wishlistItem?.product?.sku === optimizeData?.sku);

    const { data: actualData, loading } = useQuery<any>(QUERY_GET_ACTUAL_PRODUCT, {
        // nextFetchPolicy: "no-cache",
        onError: ({ message }) => {
            console.error(message, `QUERY_GET_ACTUAL_PRODUCT`, optimizeData.sku);
        },
        context: { fetchOptions: { method: 'GET' } },
        variables: {
            sku: optimizeData.sku
        },
        skip: !optimizeData?.sku
    });
    const [addProductToWishlist, { loading: loadingAddWish }] = useMutation(ADD_PRODUCT_TO_WISH_LIST, {
        onError: ({ message }) => toast.error(message)
    });

    const [removeProductWishlist, { loading: loadingremove }] = useMutation(REMOVE_PRODUCT_FROM_WISH_LIST, {
        onError: ({ message }) => toast.error(message)
    });

    const productActual = transformProductItem(actualData?.products.items?.[0] || ({} as any));

    const data: Partial<Product> = useMemo(() => {
        if (!productActual || loading) return optimizeData || {};
        // return optimizeData;
        return {
            ...(optimizeData || {}),
            ...productActual,
            images: !!optimizeData?.images?.length
                ? optimizeData?.images
                : !!productActual?.media_gallery?.length
                ? productActual?.media_gallery?.map((item: any) => item.url)
                : [optimizeData?.image?.url]
        };
    }, [optimizeData, productActual, loading]);

    const handleFavorite = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();

        if (!currentUser) {
            toast.info('Please login to wishlist');
            //   router.push("/sign-in");
            return;
        }
        productWishlist?.id
            ? removeProductWishlist({
                  variables: {
                      wishlistId: id,
                      id: productWishlist?.id
                  },
                  onCompleted(data) {
                      setWishList((prev) => ({
                          ...prev,
                          ...data?.removeProductsFromWishlist?.wishlist
                      }));
                  }
              })
            : addProductToWishlist({
                  variables: {
                      wishlistId: id,
                      sku: data?.sku
                  },
                  onCompleted(data, clientOptions) {
                      trackingWishList(data);
                      setWishList((prev) => ({
                          ...prev,
                          ...data?.addProductsToWishlist?.wishlist
                      }));
                  }
              });

        // setIsFavorite(!isFavorite);
    };

    const skeletonLoading = productItemLoading || loading;
    const href =
        data.slug &&
        getUrlCustom({
            pathname: PATH.ProductDetail,
            query: {
                slug: data.slug,
                variant: data.variants?.[0]?.product?.id,
                ...(params || {})
            }
        });
    const handleRemove = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();

        onRemove?.(data);
        // setIsFavorite(!isFavorite);
    };
    const handleAction = (e: MouseEvent<HTMLDivElement>) => {
        actions?.(data);
    };
    if (!!optimizeData.sku && !data.price_range?.minimum_price?.final_price?.value && !skeletonLoading) {
        return null;
    }

    return (
        <div
            className={classNames('flex flex-col product-item gap-2', styles.product_item, className, {
                ['!flex-row']: type === 'horizontal'
            })}
            key={data.slug}
            data-sku={data.sku}
            onClick={data?.slug ? () => onProductClick?.(data) : undefined}
        >
            <div
                className={classNames(
                    styles.product_item_hover,
                    ' relative aspect-square',
                    {
                        'max-w-40 lg:max-w-[50%] flex-1': type === 'horizontal'
                    },
                    imageProps?.className || ''
                )}
            >
                <ProductImage
                    href={href}
                    data={data as Product}
                    loading={skeletonLoading}
                    onRemove={onRemove && handleRemove}
                    {...(imageProps || {})}
                />
            </div>
            <div
                className={classNames({
                    'flex-1': type === 'horizontal'
                })}
            >
                <ProductName
                    href={href}
                    loading={skeletonLoading}
                    name={data?.name}
                    search={search}
                    {...(nameProps || {})}
                />
                {themeConfig.showDivider && <Divider dividerColor="#3E3E3E" />}
                <ProductPrice data={data} loading={skeletonLoading} />
                {/* <Star value={data.rating_summary} size={16} /> */}
            </div>

            {actions && <div onClick={handleAction}>{actions(data)} </div>}
        </div>
    );
};

export default ProductItem;
