import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import TrashIcon from '~/assets/svgs/trash.svg';
import Text from '~/components/Text';
import { Product } from '~/services/product';
import { getConfig } from '~/utils/theme';
import styles from './ProductItem.module.scss';
import Image from 'next/legacy/image';

export type Props = {
    data: Product;
    loading?: boolean;
    onRemove?: (e: any) => void;
    className?: string;
    href?: string;
};

const ProductImage = ({ data, loading, onRemove, href }: Props) => {
    const themeConfig = getConfig('ProductItem');
    return (
        <a href={href || undefined}>
            <div className={styles.back_img}>
                {!!loading && <Skeleton className="aspect-square" containerClassName="w-full h-full" />}
                {data?.images?.[0] && !loading && (
                    <img
                        src={data.images?.[0]}
                        // loader={({ src, width }) => {
                        //   return `${src}?w=${width}`;
                        // }}
                        alt={data?.name || 'Product Image'}
                        className="object-contain"
                        loading="lazy"
                        decoding="async"
                        sizes="(max-width: 600px) 100px, 200px"
                        title={data?.name || 'Product Image'}
                        width="100%"
                        height={'auto'}
                        // layout="fill"
                    />
                )}
            </div>
            <div className={styles.front_img}>
                {!!loading && <Skeleton className="aspect-square" containerClassName="w-full h-full" />}
                {data?.image && !loading && (
                    <img
                        src={data.image?.url}
                        // loader={({ src, width }) => {
                        //     return `${src}?w=${width}`;
                        // }}
                        alt={data?.name || 'Product Image'}
                        className="object-contain"
                        loading="lazy"
                        decoding="async"
                        title={data?.name || 'Product Image'}
                        width={'100%'}
                        height={'100%'}
                    />
                )}
            </div>
            {!loading && (
                <div className={classNames(styles.tags, styles[themeConfig.badgeStyle])}>
                    {data.badges?.map((badge: string) => (
                        <div key={badge} className={classNames(styles.tag_item, styles[badge])}>
                            <Text>{badge}</Text>
                        </div>
                    ))}
                </div>
            )}
            {/* <div
          className={classNames(styles.favourite, styles[size])}
          onClick={handleFavorite}
        >
          {!loading && (
            loadingAddWish || loadingremove ? (
              <span className="flex">
                <Icon
                  name="status"
                  color={Colors.GREY_300}
                  className="animate-spin"
                />
              </span>
            ) : productWishlist?.id ? (
              <HeartFillIcon
                width={24}
                height={24}
                key={productWishlist?.id}
                color={Colors.PRIMARY}
              />
            ) : (
              <HeartIcon width={24} height={24} color={Colors.PRIMARY} />
            )
          )}
        </div> */}
            {onRemove && (
                <div onClick={onRemove} className={'inline-block'}>
                    <TrashIcon
                        className={classNames(styles.remove_icon, styles[themeConfig.badgeStyle])}
                        width={24}
                        height={24}
                    />{' '}
                </div>
            )}
        </a>
    );
};

export default ProductImage;
