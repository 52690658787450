import classNames from 'classnames';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperProps, SwiperSlide } from 'swiper/react';
import Icon from '~/components/Icon';
import ProductItem from '~/components/Items/ProductItem';
import SectionTitle from '~/components/SectionTitle';
import Text from '~/components/Text';
import { Product } from '~/services/product';
import { trackingViewListItem } from '~/services/tracking';
import styles from './ProductsSlider.module.scss';
import React from 'react';

export type ProductsSliderProps = {
    title?: string;
    products?: Array<Partial<Product>>;
    loading?: boolean;
    onLoadingMore?: () => void;
    listkey: any;
    onEnter?: () => void;
    hasNext?: boolean;
    onImpressions?: (products: Array<Partial<Product>>) => void;
    directLabel?: string;
    directUrl?: string;
} & SwiperProps;

const MAX_RECORD = Number.isNaN(Number(process.env.NEXT_PUBLIC_MAX_RECORD))
    ? 100
    : Number(process.env.NEXT_PUBLIC_MAX_RECORD);

const intiBreakpoints = {
    1024: {
        slidesPerView: 4.5,
        spaceBetween: 10
    },
    768: {
        slidesPerView: 3.5,
        spaceBetween: 10
    }
};

const ProductsSlider: FC<ProductsSliderProps> = ({
    title,
    products = [],
    onLoadingMore,
    hasNext = false,
    listkey,
    loading = true,
    onEnter,
    onImpressions,
    slidesPerView = 5.5 as number,
    breakpoints = intiBreakpoints,
    navigation = true,
    pagination = true,
    modules = [],
    onSlideChange,
    directLabel,
    directUrl,
    ...restProps
}) => {
    const [swiperRef, setSwiperRef] = useState<SwiperClass>({} as SwiperClass);
    const [isSlideVisible, setIsSlideVisible] = useState(false);

    const _slidesPerView = (swiperRef?.params?.slidesPerView as number) || (slidesPerView as number);
    // Use Ref to track SKUs without triggering re-renders
    const trackedSKUsRef = useRef(new Set());
    // Ref for currently visible SKUs
    const visibleSKUsRef = useRef(new Set());

    const observerRef = useRef<any>(null);

    /**
     * Handles visibility changes of IntersectionObserver entries.
     *
     * Iterates through the entries and updates the visibleSKUsRef set accordingly.
     * If an entry is intersecting, its SKU is added to the set. Otherwise, it's removed.
     *
     * @param {IntersectionObserverEntry[]} entries - An array of IntersectionObserver entries.
     * @return {void}
     */
    function handleVisibility(entries: IntersectionObserverEntry[]) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const sku = entry.target.getAttribute('data-sku');
                if (sku) {
                    visibleSKUsRef.current.add(sku);
                }
            } else {
                const sku = entry.target.getAttribute('data-sku');
                if (sku) {
                    visibleSKUsRef.current.delete(sku);
                }
            }
        });
    }

    // Send tracked SKUs to Google Analytics
    const sendImpressions = () => {
        const skusToTrack = Array.from(visibleSKUsRef.current).filter((sku) => !trackedSKUsRef.current.has(sku));

        if (skusToTrack.length > 0) {
            onImpressions?.(products);
            trackingViewListItem({
                listKey: listkey,
                products: products.filter((product) => skusToTrack.includes(product.sku))
            });
            skusToTrack.forEach((sku) => trackedSKUsRef.current.add(sku));
        }
    };

    const handleOnSlideChange = useCallback(
        (swiper: SwiperClass) => {
            if (!!loading) return;
            if (products.length >= MAX_RECORD) {
                return;
            }
            sendImpressions();
        },
        [products]
    );

    const handleRealIndexChange = useCallback(
        (swiper: SwiperClass) => {
            if (!hasNext) return;
            if (!!loading) return;
            // if (products.length > MAX_RECORD) return;
            const currentIndex = swiper?.realIndex ?? 0;
            const silderPerView = typeof swiper?.params?.slidesPerView === 'number' ? swiper?.params?.slidesPerView : 5;

            const remainingProducts = products.length - currentIndex;

            if (remainingProducts <= silderPerView) {
                return onLoadingMore?.();
            }
        },
        [products]
    );

    /**
     * Initializes an IntersectionObserver instance to track visibility of product items.
     *
     * Disconnects any existing observer, sets up a new observer with a threshold of 0.5,
     * and starts observing product items that have not been tracked yet.
     *
     * @return {void}
     */

    const initializeObserver = () => {
        if (observerRef.current) {
            observerRef.current.disconnect();
        }
        observerRef.current = new IntersectionObserver(handleVisibility, {
            threshold: 0.5
        });

        document.querySelectorAll('.product-item').forEach((item) => {
            const sku = item.getAttribute('data-sku');
            if (sku && !trackedSKUsRef.current.has(sku)) {
                // Kiểm tra xem SKU đã được track hay chưa
                observerRef.current.observe(item);
            }
        });
    };

    useEffect(() => {
        if (!products.length) return;
        initializeObserver();

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [products]);

    // const swiperRef = useRef<any>(null);

    // check invisible product-list
    useEffect(() => {
        const ProductsSliderElement = document.getElementById(listkey);
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        onEnter?.();
                        setIsSlideVisible(true);
                        observer.disconnect();
                    }
                });
            },
            {
                threshold: 0.5,
                rootMargin: '0px 0px 100px 0px'
            }
        );

        ProductsSliderElement && observer.observe(ProductsSliderElement);

        return () => {
            ProductsSliderElement && observer.unobserve(ProductsSliderElement);
        };
    }, []);

    const handleAfterInit = (swiper: SwiperClass) => {
        if (!products.length || !!swiper.destroyed) return;
        const silderPerView = swiper?.params?.slidesPerView ?? 5;
        const productOnView = [...products].slice(0, silderPerView as number);
        productOnView?.map(({ sku }) => {
            visibleSKUsRef.current.add(sku);
        });
        sendImpressions();
    };

    return (
        <div className="product-slider" id={listkey}>
            {(!!products.length || !!loading) && (
                <React.Fragment>
                    {!!title && (
                        <SectionTitle
                            text={title}
                            action={
                                directUrl ? (
                                    <a href={directUrl} className="flex items-center gap-1 text-primary">
                                        <Text type="title-3">{directLabel}</Text>
                                        <Icon name="arrow-up-right" />
                                    </a>
                                ) : undefined
                            }
                        />
                    )}
                    {!!loading && !products.length && (
                        <Swiper
                            {...restProps}
                            slidesPerView={2.5}
                            spaceBetween={10}
                            speed={1000}
                            breakpoints={{
                                ...breakpoints,
                                1024: {
                                    ...breakpoints[1024],
                                    slidesPerView: _slidesPerView
                                }
                            }}
                            modules={[...modules]}
                        >
                            {skeletonProductList({
                                slidesPerView: Math.ceil(_slidesPerView)
                            })}
                        </Swiper>
                    )}
                    {!!products.length && (
                        <Swiper
                            {...restProps}
                            onSwiper={setSwiperRef}
                            slidesPerView={2.5}
                            spaceBetween={10}
                            onAfterInit={handleAfterInit}
                            navigation={
                                products.length > _slidesPerView
                                    ? {
                                          enabled: true,
                                          hideOnClick: true
                                      }
                                    : undefined
                            }
                            cssMode={true}
                            freeMode={true}
                            onRealIndexChange={handleRealIndexChange}
                            watchSlidesProgress={true}
                            breakpoints={{
                                ...breakpoints,
                                1024: {
                                    ...breakpoints[1024],
                                    slidesPerView: _slidesPerView,
                                    cssMode: false
                                }
                            }}
                            onSlideChange={handleOnSlideChange}
                            modules={[Navigation, FreeMode, ...modules]}
                            wrapperClass={classNames('items-stretch', styles.productSliderWrapper)}
                        >
                            {[...products]?.map((data: Partial<Product & { loading?: boolean }>, index: number) => (
                                <SwiperSlide key={data.id} className={styles.productItem} data-slide-id={data.id}>
                                    <div className="h-full" data-sku={data.sku}>
                                        <ProductItem data={data} params={{ b: listkey, p: index }} />
                                    </div>
                                </SwiperSlide>
                            ))}
                            {!!hasNext
                                ? skeletonProductList({
                                      slidesPerView: Math.ceil(_slidesPerView)
                                  })
                                : null}
                        </Swiper>
                    )}
                </React.Fragment>
            )}
        </div>
    );
};

export default ProductsSlider;

export const skeletonProductList = ({ slidesPerView }: { slidesPerView: number }) => {
    return Array.from({ length: slidesPerView }, (_, index) => (
        <SwiperSlide key={index} className="">
            <ProductItem data={{}} loading={true} key={index} />
        </SwiperSlide>
    ));
};
